html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  z-index: 1;
  font-family: "universal450", "Inter", "sans-serif";
  background: conic-gradient(
    from 107.18deg at -23.83% 30.47%,
    #faf9ff -13.69deg,
    #dae7ff 2.73deg,
    #e3e3ff 26.79deg,
    #f4f7ff 45.19deg,
    #e5e5fb 176.72deg,
    #fff 313.72deg,
    #e5edff 331.18deg,
    #faf9ff 346.31deg,
    #dae7ff 362.73deg
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.Table-content {
  width: 100%;
  border-top: 1px solid black;
  margin-top: 30px;
}

.Table-content th {
  white-space: nowrap;
  text-align: center;
}

.Table-content th:first-child {
  text-align: left;
  white-space: normal;
}

.Table-content td {
  text-align: center;
}

.Table-content td:first-child {
  text-align: left;
  white-space: normal;
  max-width: 630px;
}

.content-container {
  padding: 4rem;
}

@media screen and (max-width: 781px) {
  .content-container {
    padding: 1em;
  }
  .Table-content tr :not(:first-child) {
    padding: 2% 2% 2% 0%;
  }
  .Table-content tr :first-child {
    padding: 1% 5% 1% 0%;
  }
  .Table-content thead :not(:first-child) {
    text-orientation: mixed;
    writing-mode: vertical-rl;
  }
}

/* page transition */
.Table-content {
  transition: transform 0.5s ease-in-out;
}
.sub-header {
  transition: transform 0.5s ease-in-out;
  margin-top: 20px;
}
.sub-header span {
  position: relative;
  top: -1px;
}

.detail-container {
  transition: transform 0.5s ease-in-out;
  position: absolute;
  left: 100%;
  width: 100%;
  padding: 0rem 4rem;
}
.detail-container button {
  margin-top: 10px;
  border: none;
  background: none;
  cursor: pointer;
  color: blue;
}
.detail-container button:hover {
  color: #343078;
  transform: translateX(-2px);
}

.Table-content.animate {
  transform: translateX(-110%);
}

.detail-container.animate {
  transform: translateX(-100%);
}

.sub-header.animate {
  transform: translateX(-110%);
}

@media screen and (max-width: 781px) {
  .detail-container.animate {
    padding: 1rem;
  }
}
