.mainNavigation {
  display: flex;
  justify-content: space-between;
  background-color: #000b1f;
  width: 100%;
}

.logo {
  display: flex;
  justify-content: start;
  margin-right: auto;
  padding: 1rem 0rem 0rem 1rem;
  width: 210px;
  height: 160px;
}

.checkstep-logo-burger {
  position: absolute;
  top: 2rem;
  z-index: 102;
  width: 130px;
  height: 60px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: URL("../assets/Default-White.png");
}
.checkstep-logo-burger span {
  position: absolute;
  top: -1.1rem;
  left: 0rem;
  color: white;
  font-size: 11px;
  white-space: nowrap;
  text-decoration: none;
}

.checkstep-logo {
  display: flex;
  justify-content: end;
  margin-left: auto;
  margin-right: 1rem;
  margin-top: 2rem;
  width: 130px;
  height: 60px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: URL("../assets/Default-White.png");
}
.checkstep-logo span {
  display: flex;
  justify-content: end;
  margin-left: auto;
  margin-right: 0rem;
  margin-top: -1rem;
  color: white;
  font-size: 11px;
  white-space: nowrap;
  text-decoration: none;
}

.checkstep-logo span, a:link, a:visited {
  text-decoration: none;
}

.navButton-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: -5px;
}

.navButton {
  display: inline;
  margin-left: 30px;
  padding-bottom: 5px;
}

.navButton a {
  color: white;
  text-decoration: none;
  border-bottom: 3px solid currentColor;
  font-size: 18px;
  padding: 0 5px;
  padding-bottom: 5px;
  display: inline-block;
  white-space: nowrap;
}

.active {
  color: #00f8ff !important;
  text-decoration: none;
}

/* Tablet and mobile */
@media screen and (max-width: 910px) {
  .navButton-container {
    display: none;
  }

  .checkstep-logo {
    display: none;
  }
}
